function setEqualHeight() {
    $(".grid-news-item-025 article .article-content-wrapper").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).on('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit_1 = 42,
        scroll_limit = 82,
        initial_height = 100,
        target_height = 60;
    switch (current_breakpoint) {
        case "xxlarge":
            scroll_limit_1 = 42;
            scroll_limit = 82;
            initial_height = 100;
            target_height = 60;
            break;
        case "xlarge":
            scroll_limit_1 = 42;
            scroll_limit = 82;
            initial_height = 100;
            target_height = 60;
            break;
        case "large":
            scroll_limit_1 = 42;
            scroll_limit = 62;
            initial_height = 80;
            target_height = 60;
            break;
        case "medium":
            scroll_limit_1 = 0;
            scroll_limit = 0;
            initial_height = 60;
            target_height = 60;
            break;
        case "small":
            scroll_limit_1 = 0;
            scroll_limit = 0;
            initial_height = 60;
            target_height = 60;
            break;
        case "xsmall":
            scroll_limit_1 = 31;
            scroll_limit = 31;
            initial_height = 60;
            target_height = 60;
            break;
        default:
            scroll_limit_1 = 40;
            scroll_limit = 80;
            initial_height = 100;
            target_height = 60;
            break;
    }
    if (current_breakpoint_num > 0) {
        if ($(window).scrollTop() === 0) {
            $("#header-wrapper").css("margin-top", "");
            logo_height = initial_height;
        } else if ($(window).scrollTop() > 0 && $(window).scrollTop() <= scroll_limit_1) {
            $("#header-wrapper").css("margin-top", -$(window).scrollTop() + "px");
            logo_height = initial_height;
        } else if (current_breakpoint_num > 3 && $(window).scrollTop() > scroll_limit_1 && $(window).scrollTop() <= scroll_limit) {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            logo_height = initial_height - ($(window).scrollTop() / scroll_limit) * (initial_height - target_height);
        } else {
            $("#header-wrapper").css("margin-top", -scroll_limit_1 + "px");
            logo_height = target_height;
            $("#header-main-wrapper").addClass("fixed");
        }
        $("#logo a").css("height", logo_height + "px");
    } else {
        $("#logo a").css("height", target_height + "px");
    }
});
